/**
 * Tutorial composable.
 */
export const useTutorial = createSharedComposable(() => {
   /**
    * Current route.
    */
   const route = useRoute()

   /**
    * Base name.
    */
   const baseName = useRouteBaseName()

   /**
    * Current step.
    */
   const step = useQueryParam('step', {
      defaultValue: () => 0,
      getter: Number,
      setter: String,
   })

   /**
    * Has already been shown.
    */
   const hasBeenShown = useLocalStorage('tutorial', false)

   /**
    * Is on tutoral page.
    */
   const isOnTutorialPage = computed(() => {
      return baseName(route) === 'get-started'
   })

   /**
    * Should redirect.
    */
   const shouldRedirect = computed(() => {
      return !import.meta.server && !toValue(hasBeenShown) && !toValue(isOnTutorialPage)
   })

   return {
      hasBeenShown,
      isOnTutorialPage,
      shouldRedirect,
      step,
   }
})
